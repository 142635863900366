<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/nh200.webp">
    <h2 class="name_h2">エコピア NH200</h2>

    <p class="access_p">
    エコピア NH200は雨の日のブレーキ性能や低燃費性能に優れたバランスの良いタイヤになります。<br>
    純正タイヤ相当の性能で、今よりタイヤのグレードを落としたくない方に人気です。<br>
    また、雨や霧の多い御殿場では濡れた路面のブレーキ性能が強いエコピア NH200を一番おすすめしています。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">セダン / スポーツ / 一部ミニバン / 軽 / コンパクト</p>

    <div class="charge2">
    <div class="charge_table">
      <h2 class="charge_table_h2"><span class="charge_table_span">サイズ表・メーカー希望小売価格</span><br>※参考価格</h2>
      <table class="biz-hour">
        <tbody>
          <tr>
            <th>サイズ</th>
            <th>税込<span class="price_span"> 1本</span></th>
          </tr>
          <tr>
            <td>19インチ</td>
          </tr>
          <tr>
            <td>195/50R19 88H</td>
            <td class="reds"><span class="red_p">¥</span>41,470</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>18インチ</td>
          </tr>
          <tr>
            <td>225/45R18 95W XL</td>
            <td class="reds"><span class="red_p">¥</span>47,080</td>
          </tr>
          <tr>
            <td>215/45R18 93W	XL</td>
            <td class="reds"><span class="red_p">¥</span>47,080</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>17インチ</td>
          </tr>
          <tr>
            <td>225/45R17 94W	XL</td>
            <td class="reds"><span class="red_p">¥</span>41,690</td>
          </tr>
          <tr>
            <td>215/45R17 91W	XL</td>
            <td class="reds"><span class="red_p">¥</span>40,370</td>
          </tr>
          <tr>
            <td>225/50R17 94V</td>
            <td class="reds"><span class="red_p">¥</span>42,130</td>
          </tr>
          <tr>
            <td>215/50R17 91V</td>
            <td class="reds"><span class="red_p">¥</span>39,820</td>
          </tr>
          <tr>
            <td>225/55R17 97V</td>
            <td class="reds"><span class="red_p">¥</span>35,090</td>
          </tr>
          <tr>
            <td>215/55R17 94V</td>
            <td class="reds"><span class="red_p">¥</span>33,330</td>
          </tr>
          <tr>
            <td>195/60R17 90H</td>
            <td class="reds"><span class="red_p">¥</span>31,130</td>
          </tr>
          <tr>
            <td>195/45R17 81W</td>
            <td class="reds"><span class="red_p">¥</span>38,500</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>16インチ</td>
          </tr>
          <tr>
            <td>195/50R16 84V</td>
            <td class="reds"><span class="red_p">¥</span>31,240</td>
          </tr>
          <tr>
            <td>205/55R16 91V</td>
            <td class="reds"><span class="red_p">¥</span>29,480</td>
          </tr>
          <tr>
            <td>195/55R16 87V</td>
            <td class="reds"><span class="red_p">¥</span>27,390</td>
          </tr>
          <tr>
            <td>215/60R16 95H</td>
            <td class="reds"><span class="red_p">¥</span>28,930</td>
          </tr>
          <tr>
            <td>205/60R16 92H</td>
            <td class="reds"><span class="red_p">¥</span>27,390</td>
          </tr>
          <tr>
            <td>205/65R16 95H</td>
            <td class="reds"><span class="red_p">¥</span>30,910</td>
          </tr>
          <tr>
            <td>185/55R16 83V</td>
            <td class="reds"><span class="red_p">¥</span>27,720</td>
          </tr>
          <tr>
            <td>185/60R16 86H</td>
            <td class="reds"><span class="red_p">¥</span>24,420</td>
          </tr>
          <tr>
            <td>175/60R16 82H</td>
            <td class="reds"><span class="red_p">¥</span>22,880</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>15インチ</td>
          </tr>
          <tr>
            <td>195/60R15 88H</td>
            <td class="reds"><span class="red_p">¥</span>23,540</td>
          </tr>
          <tr>
            <td>195/65R15 91H</td>
            <td class="reds"><span class="red_p">¥</span>23,210</td>
          </tr>
          <tr>
            <td>185/55R15 82V</td>
            <td class="reds"><span class="red_p">¥</span>24,090</td>
          </tr>
          <tr>
            <td>175/55R15 77V</td>
            <td class="reds"><span class="red_p">¥</span>23,320</td>
          </tr>
          <tr>
            <td>165/55R15 75V</td>
            <td class="reds"><span class="red_p">¥</span>18,700</td>
          </tr>
          <tr>
            <td>185/60R15 84H</td>
            <td class="reds"><span class="red_p">¥</span>22,110</td>
          </tr>
          <tr>
            <td>175/60R15 81H</td>
            <td class="reds"><span class="red_p">¥</span>20,570</td>
          </tr>
          <tr>
            <td>165/60R15 77H</td>
            <td class="reds"><span class="red_p">¥</span>18,920</td>
          </tr>
          <tr>
            <td>185/65R15 88S</td>
            <td class="reds"><span class="red_p">¥</span>20,900</td>
          </tr>
          <tr>
            <td>175/65R15 84H</td>
            <td class="reds"><span class="red_p">¥</span>18,700</td>
          </tr>
          <tr>
            <td>165/65R15 81S</td>
            <td class="reds"><span class="red_p">¥</span>17,160</td>
          </tr>
          <tr>
            <td>145/65R15 72S</td>
            <td class="reds"><span class="red_p">¥</span>13,750</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>14インチ</td>
          </tr>
          <tr>
            <td>185/65R14 86S</td>
            <td class="reds"><span class="red_p">¥</span>18,480</td>
          </tr>
          <tr>
            <td>185/70R14 88S</td>
            <td class="reds"><span class="red_p">¥</span>18,150</td>
          </tr>
          <tr>
            <td>165/55R14 72V</td>
            <td class="reds"><span class="red_p">¥</span>17,600</td>
          </tr>
          <tr>
            <td>165/60R14 75H</td>
            <td class="reds"><span class="red_p">¥</span>16,830</td>
          </tr>
          <tr>
            <td>175/65R14 82S</td>
            <td class="reds"><span class="red_p">¥</span>16,940</td>
          </tr>
          <tr>
            <td>165/65R14 79S</td>
            <td class="reds"><span class="red_p">¥</span>14,850</td>
          </tr>
          <tr>
            <td>155/65R14 75H</td>
            <td class="reds"><span class="red_p">¥</span>13,750</td>
          </tr>
          <tr>
            <td>175/70R14 84S</td>
            <td class="reds"><span class="red_p">¥</span>16,060</td>
          </tr>
          <tr>
            <td>165/70R14 81S</td>
            <td class="reds"><span class="red_p">¥</span>14,850</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>13インチ</td>
          </tr>
          <tr>
            <td>165/65R13 77S</td>
            <td class="reds"><span class="red_p">¥</span>14,630</td>
          </tr>
          <tr>
            <td>155/65R13 73S</td>
            <td class="reds"><span class="red_p">¥</span>12,210</td>
          </tr>
          <tr>
            <td>155/70R13 75S</td>
            <td class="reds"><span class="red_p">¥</span>12,760</td>
          </tr>
          <tr>
            <td>165/80R13 83S</td>
            <td class="reds"><span class="red_p">¥</span>14,520</td>
          </tr>
          <tr>
            <td>155/80R13 79S</td>
            <td class="reds"><span class="red_p">¥</span>13,420</td>
          </tr>
          <tr>
            <td>145/80R13 75S</td>
            <td class="reds"><span class="red_p">¥</span>11,110</td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>

    <CarouselsSedan/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsSedan from '@/components/CarouselsSedan.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsSedan,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}

.charge2{
  padding-bottom: 3rem;
  padding-top: 0rem;
}
@media screen and (min-width: 520px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
@media screen and (min-width: 1280px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
    padding-right: 10%;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}

.biz-hour th, .biz-hour td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  /* ２番目以降の線 */
  /* 色はお好みで */
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* 上段の背景色 */
.biz-hour th {
  /* background: black; */
  font-weight: bold;
}

/* 土曜日の文字色 */
.biz-hour th.sat {
  color: #3db2da;
}

/* 日曜日の文字色 */
.biz-hour th.sun {
  color: #e66a6a;
}
</style>
